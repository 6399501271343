/* todo:choose colors */
.error-status {
  color: red;
}
.completed-status {
  color: rgb(66, 163, 63);
}
.info-status {
  color: rgb(63, 103, 220);
}
.warning-status {
  color: rgb(247, 197, 4);
}
.waiting-status {
  color: rgb(83, 128, 201);
}
